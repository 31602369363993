// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internal-tsx": () => import("./../src/pages/internal.tsx" /* webpackChunkName: "component---src-pages-internal-tsx" */),
  "component---src-pages-netflix-deliverable-naming-tsx": () => import("./../src/pages/netflix-deliverable-naming.tsx" /* webpackChunkName: "component---src-pages-netflix-deliverable-naming-tsx" */),
  "component---src-pages-timelapse-calculator-tsx": () => import("./../src/pages/timelapse-calculator.tsx" /* webpackChunkName: "component---src-pages-timelapse-calculator-tsx" */)
}

